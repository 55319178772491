<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>Orders</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#802a27">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ newOrders.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">New Orders</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#311211">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ getOrderList.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">Total Orders</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#b36f6c">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ onHoldOrders.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">On Hold</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#6cacb2">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ canceledOrders.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">Canceled Orders</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title>Messages</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#b36f6c">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ newMessages.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">New Messages</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#6cacb2">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ getMessages.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">Total Messages</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title primary-title>Products</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#25848d">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ offlineProducts.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">Offline Products</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#0b3034">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ allProducts.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">Total Products</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card>
          <v-card-title primary-title>Users</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#802a27">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ allUsers.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">Total Users</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <v-card min-height="100" color="#25848d">
                  <v-card-title
                    class="white--text display-2 justify-center darken-5"
                  >{{ admins.length }}</v-card-title>
                  <v-card-text class="white--text text-center darken-5">Admins</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      // newOrders: "",
      // newMessages: "",
      // totalUsers: "",
      // totalProducts: ""
    };
  },
  computed: {
    ...mapGetters([
      "getMessages",
      "getOrderList",
      "allUsers",
      "allProducts",
      "allProductCategories"
    ]),
    // offlineProducts() {
    //   return this.allProducts.filter(product => product.active === false);
    // },
    admins() {
      return this.allUsers.filter(user => user.role === "admin");
    },
    offlineProducts() {
      return this.allProducts.filter(product => product.active === false);
    },
    newMessages() {
      return this.getMessages.filter(message => message.status === "unread");
    },
    newOrders() {
      return this.getOrderList.filter(order => order.status === "new");
    },
    canceledOrders() {
      return this.getOrderList.filter(order => order.status === "canceled");
    },
    onHoldOrders() {
      return this.getOrderList.filter(order => order.status === "on hold");
    }
  }
};
</script>
